


















import isEqualBy from '@/tools/is-equal-by'

import SimulationForm from '@/components/shared/entities/simulation-form.vue'

export default {
  name: 'simulation-properties',
  components: {
    'simulation-form': SimulationForm,
  },
  data() {
    return {
      tmpEntity: this.getTmpEntity(),
    }
  },
  mounted() {
    this.focusNameInput()
  },
  methods: {
    focusNameInput() {
      this.$nextTick(() => this.$refs.simulationForm.focus())
    },
    applySimulationChange() {
      this.$store.dispatch('modifySelectedEntity', this.tmpEntity)
    },
    resetSimulationChange() {
      this.tmpEntity = this.getTmpEntity()
    },
    getTmpEntity() {
      const entity = this.$store.state.selectedEntity?.entity
      if (!entity) return null

      return { ...entity }
    },
  },
  computed: {
    simulationEdited() {
      if (!this.selection) return false
      return !isEqualBy(this.selection.entity, this.tmpEntity, ['name', 'solverConf', 'annotation'])
    },
    selection() {
      return this.$store.state.selectedEntity
    },
  },
  watch: {
    selection() {
      this.tmpEntity = this.getTmpEntity()
      this.focusNameInput()
    },
  },
}
