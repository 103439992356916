


















































import { SIM_TRACE_MAX_SIZE } from '@/constants'

import StepsStimulationForm from './steps-stimulation-form.vue'
import SpatialSamplingConf from './steps-conf-form/spatial-sampling-conf-form.vue'

export default {
  name: 'sim-steps-conf-form',
  props: ['value'],
  components: {
    'steps-stimulation-form': StepsStimulationForm,
    'spatial-sampling-conf': SpatialSamplingConf,
  },
  data() {
    return {
      conf: { ...this.value },
    }
  },
  methods: {
    onChange() {
      this.$emit('input', { ...this.conf, valid: this.isValid })
    },
  },
  computed: {
    traceMaxSizeReached() {
      const observableN = this.$store.state.model.observables.length
      return (observableN * this.conf.tEnd) / this.conf.dt > SIM_TRACE_MAX_SIZE
    },
    isValid() {
      return this.conf.dt && this.conf.tEnd && !this.traceMaxSizeReached
    },
  },
  watch: {
    value() {
      this.conf = { ...this.value }
    },
  },
}
