















































import { Solver } from '@/types' //eslint-disable-line no-unused-vars

import StepsConfForm from '@/components/shared/sim/steps-conf-form.vue'
import NfsimConfForm from '@/components/shared/sim/nfsim-conf-form.vue'

const solverLabel = {
  nfsim: 'NFsim',
  tetexact: 'STEPS: Tetexact',
  tetopsplit: 'STEPS: Tetoptsplit',
  ode: 'BNG: ode',
  ssa: 'BNG: ssa',
}

export default {
  name: 'simulation-form',
  props: ['value'],
  components: {
    'steps-conf-form': StepsConfForm,
    'nfsim-conf-form': NfsimConfForm,
  },
  data() {
    return {
      solvers: ['tetexact', 'tetopsplit', 'nfsim', 'ode', 'ssa'],
      simulation: { ...this.value },
      solverLabel,
    }
  },
  methods: {
    onSolverChange() {
      this.simulation.solverConf = {
        valid: true,
        dt: 0.01,
        tEnd: 10,
        stimulation: {
          size: 0,
          targetValues: [],
          data: [],
        },
        spatialSampling: {
          enabled: false,
          structures: [],
          observables: [],
        },
      }
      this.onSimulationChange()
    },
    onSimulationChange() {
      this.simulation.valid = this.isValid()
      this.$emit('input', this.simulation)
    },
    isValid() {
      return !!this.simulation.name.trim() && !!this.simulation.solverConf.valid && !!this.simulation.solver
    },
    focus() {
      this.$refs.nameInput.focus()
    },
    getInvalidReason(solver: Solver) {
      if (!this.solvers.includes(solver)) throw new Error(`Unrecognized solver ${solver}`)

      return ''
    },
  },
  computed: {
    model() {
      return this.$store.state.model
    },
  },
  watch: {
    value() {
      this.simulation = { ...this.value }
    },
  },
}
