


























































































// TODO: cleanup, refactor
import sortBy from 'lodash/sortBy'

import constants from '@/constants'
import tools from '@/tools/model-tools'
import { get as getr } from '@/services/api'
import { Parameter } from '@/types'
import { AxiosResponse } from 'axios'

import NfsimStimuliImport from './nfsim-stimulation-import.vue'

const { StimulusTypeEnum: StimType } = constants

const stimulusTypes = [
  {
    type: StimType.SET_PARAM,
    label: 'set param',
  },
]

const tableColumns = [
  {
    title: 'Time, s',
    key: 't',
    maxWidth: 140,
  },
  {
    title: 'Operation',
    key: 'type',
    width: 110,
  },
  {
    title: 'Target',
    key: 'target',
    maxWidth: 280,
  },
  {
    title: 'Value',
    slot: 'value',
  },
  {
    title: ' ',
    slot: 'action',
    width: 40,
  },
]

const defaultStimulus = {
  t: null,
  type: StimType.SET_PARAM,
  target: null,
  value: null,
}

export default {
  name: 'nfsim-stimuli-form',
  props: ['value'],
  components: {
    'stimuli-import': NfsimStimuliImport,
  },
  data() {
    return {
      tableColumns,
      stimulusTypes,
      largeStimulation: false,
      stimuli: [],
      stimulation: {
        size: 0,
        data: [],
        targetValues: [],
      },
      stimulus: { ...defaultStimulus },
      importModalVisible: false,
      parameters: [],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.stimulation = { ...this.value }
      this.largeStimulation = this.stimulation.size > 100
      this.stimuli = this.getStimuli()
      this.parameters = await this.getParameters()
    },
    async getParameters() {
      const model = this.$store.state.model

      if (!model?.id) return []

      const res: AxiosResponse<Parameter[]> = await getr('parameters', {
        user_id: model?.user_id,
        model_id: model?.id,
      })

      return res.data
    },
    getStimuli() {
      return this.stimulation.size < 100 ? tools.decompressStimulation(this.stimulation) : []
    },
    addStimulus() {
      this.stimuli.push(this.stimulus)
      this.stimuli = sortBy(this.stimuli, (stimulus) => stimulus.t)
      this.setDefaultStimulusValue()
      this.updateStimulation()
      this.onStimuliChange()
    },
    removeStimulus(index) {
      this.stimuli.splice(index, 1)
      this.updateStimulation()
      this.onStimuliChange()
    },
    setDefaultStimulusValue() {
      this.stimulus = { ...defaultStimulus }
    },
    onStimTypeChange() {
      Object.assign(this.stimulus, { target: null, value: null })
    },
    onStimuliChange() {
      this.$emit('input', { ...this.stimulation })
    },
    onImportClick() {
      this.importModalVisible = true
    },
    onImport(stimulation) {
      this.stimulation = stimulation
      this.largeStimulation = stimulation.size > 100
      this.stimuli = this.getStimuli()
      this.importModalVisible = false
      this.onStimuliChange()
    },
    onClearClick() {
      this.stimuli = []
      this.updateStimulation()
      this.largeStimulation = false
      this.onStimuliChange()
    },
    updateStimulation() {
      this.stimulation = tools.compressStimuli(this.stimuli)
    },
  },
  computed: {
    addStimulusBtnEnabled() {
      const { t, type, target, value } = this.stimulus

      return t && type && target && typeof value === 'number'
    },
  },
  watch: {
    value(stimulation) {
      this.stimulation = { ...stimulation }
      this.stimuli = this.getStimuli()
    },
  },
}
